import React, {Component} from "react";
import {connect} from "react-redux";
import {BrowserRouter as Router, Route, Switch} from "react-router-dom";
import "./components/assets/styles/styles.scss";
import 'antd/dist/antd.css';
import AuthRoute from "./components/auth-route";
import TabBar from "./components/tab-bar";
import Login from "./components/login";
import Delivery from "./components/orders/delivery";
import RouteMap from "./components/route-map";
import Menu from "./components/menu";
import Personal from "./components/personal";
import {message} from "antd";
import * as actions from './actions/actions';
import PageNotFound from "./components/page-not-found";
import AppConfig from "./AppConfig";

class App extends Component {
    constructor(props) {
        super(props);
        this.locationInterval = null;
    }

    componentDidMount() {
        const location = "geolocation" in navigator;
        if (!location) {
            message.error('Ваше устройство не поддерживает геолокацию!', 0);
        } else {
            this.getPosition();
            this.setLocationInterval();
        }
    }

    getPosition = () => navigator.geolocation.getCurrentPosition(this.updatePositions, this.errorPosition);

    setLocationInterval = () => {
        this.locationInterval = setInterval(this.getPosition, AppConfig._updateLocationInterval * 1000);
    };

    updatePositions = (position) => {
        if (!this.props.location.enableLocation) this.props.dispatch(actions.enableLocation(true));
        this.props.dispatch(actions.location(position.coords));
    };

    errorPosition = (err) => {
        if (err.message === "User denied Geolocation") {
            if (this.props.location.enableLocation) this.props.dispatch(actions.enableLocation(false));
            message.error('Для работы с приложением необходимо разрешить доступ к Вашей геолокации!', AppConfig._notificationDuration);
            return;
        }
        message.error('Не удалось получить Вашу геопозицию!', AppConfig._notificationDuration);
    };

    componentWillUnmount() {
        if (this.locationInterval) clearInterval(this.locationInterval);
    }

    render() {
        return (
            <div className={`Courier-helper ${this.props.theme}`}>
                <div className="Courier-helper__content">
                    <Router>
                        <Switch>
                            <AuthRoute path="/" component={Delivery} isLogged={this.props.isLogged} exact/>
                            <AuthRoute path="/menu" component={Menu} isLogged={this.props.isLogged} exact/>
                            <AuthRoute path="/map" component={RouteMap} isLogged={this.props.isLogged} exact/>
                            <AuthRoute path="/personal" component={Personal} isLogged={this.props.isLogged} exact/>
                            <Route path="/login" component={Login} exact/>
                            <AuthRoute isLogged={this.props.isLogged} component={PageNotFound}
                            />
                        </Switch>
                        {this.props.isLogged && <TabBar/>}
                    </Router>
                </div>
            </div>
        )
    }
}

const mapStateToProps = (state) => {
    const {isLogged, theme} = state.app;
    const { orders, location } = state;
    return {
        isLogged,
        orders,
        theme,
        location
    }
};

const mapDispatchToProps = (dispatch) => {
    return {
        dispatch
    }
};

export default connect(mapStateToProps, mapDispatchToProps)(App);