import * as actions from "../actions/actions";
import StorageService from "../services/storage-service";
import Types from "../types";
import AppConfig from "../AppConfig";

const ID = "ID";
const ORGANIZATION_ID = "ORGANIZATION_ID";
const COURIER_NAME = "COURIER_NAME";
const PHONE = "PHONE";
const ROLE = "ROLE";
const THEME = "THEME";

const id = StorageService.get(ID);
const organization = StorageService.get(ORGANIZATION_ID);
const name = StorageService.get(COURIER_NAME);
const phone = StorageService.get(PHONE);
const role = StorageService.get(ROLE);
const theme = StorageService.get(THEME);

const initialState = {
    isLogged: !!id,
    loginStatus: Types.NOT_FETCHED,
    loginError: "",
    courierName: name || "",
    id: id || "",
    organizationId: organization || [],
    phone: phone || [],
    role: role || [],
    theme: theme || AppConfig._defaultTheme,
};

export const app = (state = initialState, action) => {

    switch (action.type) {
        case actions.LOG_IN:
            const {courierName, id, organizationId, phone, role} = action.payload;
            StorageService.set(COURIER_NAME, courierName);
            StorageService.set(ID, id);
            StorageService.set(ORGANIZATION_ID, organizationId);
            StorageService.set(PHONE, phone);
            StorageService.set(ROLE, role);
            return {
                ...state,
                isLogged: true,
                loginStatus: Types.FETCHED,
                courierName: courierName,
                id: id,
                organizationId: organizationId,
                phone: phone,
                role: role,
            };

        case actions.LOGIN_STATUS_FETCHING:
            return {
                ...state,
                loginStatus: Types.FETCHING,
            };

        case actions.LOGIN_ERROR:
            return {
                ...state,
                loginError: action.payload,
                loginStatus: Types.NOT_FETCHED
            };

        case actions.LOG_OUT:
            StorageService.remove(COURIER_NAME);
            StorageService.remove(ID);
            StorageService.remove(ROLE);
            StorageService.remove(ORGANIZATION_ID);
            StorageService.remove(PHONE);
            return {
                isLogged: false,
                loginStatus: Types.NOT_FETCHED,
                loginError: "",
                courierName: "",
                id: "",
                organizationId: "",
                phone: "",
                role: "",
                theme: state.theme,
            };

        case actions.THEME:
            if (Object.values(Types.themes).includes(action.payload)) {
                StorageService.set(THEME, action.payload);
                return {
                    ...state,
                   theme: action.payload
                };
            } else return state;

        default:
            return state;
    }
};