import React from "react";

const Preloader = () => (
    <div className="Preloader">
        <div className="bar1 bar" />
        <div className="bar2 bar" />
        <div className="bar3 bar" />
        <div className="bar4 bar" />
        <div className="bar5 bar" />
        <div className="bar6 bar" />
    </div>
);

export default Preloader;