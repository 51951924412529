import axios from "axios";

export default class ApiService {

    _baseApi = 'https://api.ybdyb.ru/api/';
    _authBase = this._baseApi + 'courieruser/login';
    _ordersBase = this._baseApi + 'courier/orders/';
    _completeBase = this._baseApi + 'courier/complete/';

    auth = async (login) => {
        return await axios.post(this._authBase, {login});
    };

    loadOrders = async (id) => {
        return await axios.get(`${this._ordersBase}${id}`);
    };

    completeOrders = async (orgId, courId, orderId) => {
        return await axios.get(`${this._completeBase}${orgId}/${courId}/${orderId}`);
    };
}