export default class Types {
    static NOT_FETCHED = "NOT_FETCHED";
    static FETCHING = "FETCHING";
    static FETCHED = "FETCHED";

    static ordersStatus = {
        pending: { value: "PENDING", label: "В ожидании"},
        delivered: { value: "DELIVERED", label: "Доставлен"},
        deliveredLate: { value: "DELIVERED_LATE", label: "Доставлен с опозданием"},
    };

    static themes = {
        light: "light",
        dark: "dark"
    };
}