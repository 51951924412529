import * as actions from "../actions/actions";
import Types from "../types";
import StorageService from "../services/storage-service";

const ORDERS_LIST = "ORDERS_LIST";
const LAST_UPDATE_INTERVAL = "LAST_UPDATE_INTERVAL";
const ORDERS_SORT = "ORDERS_SORT";

const ordersList = StorageService.get(ORDERS_LIST);
const lastOrdersUpdate = StorageService.get(LAST_UPDATE_INTERVAL);

const saveOrdersSort = (orders) => {
    StorageService.set(ORDERS_SORT, orders.map(o => o.orderId));
};

const getOrdersSort = (orders) => {
    const ordersSort = StorageService.get(ORDERS_SORT);
    if (!ordersSort) return orders;
    const sortedOrders = [];
    ordersSort.forEach(o => {
        const currentOrder = orders.find(i => i.orderId === o);
        if (currentOrder) {
            sortedOrders.push(currentOrder);
        }
    });
    const otherOrders = orders.filter(i => !ordersSort.includes(i.orderId));
    return [...sortedOrders, ...otherOrders]
};

const initialState = {
    status: Types.NOT_FETCHED,
    error: '',
    selectedOrder: '',
    list: ordersList || [],
    lastOrdersUpdate: lastOrdersUpdate || null,
};

export const orders = (state = initialState, action) => {

    switch (action.type) {
        case actions.ORDERS:
            const list = getOrdersSort(action.payload);
            StorageService.set(ORDERS_LIST, list);
            StorageService.set(LAST_UPDATE_INTERVAL, +new Date());
            return {
                status: Types.FETCHED,
                error: '',
                list,
                lastOrdersUpdate: +new Date(),
            };

        case actions.ORDERS_STATUS_FETCHING:
            return {
                ...state,
                status: Types.FETCHING,
            };

        case actions.ORDERS_ERROR:
            return {
                ...state,
                error: action.payload,
                status: Types.NOT_FETCHED
            };

        case actions.ORDERS_POSITION_UP:
            const upIndex = state.list.findIndex(o => o.orderNumber === action.payload);
            if (upIndex === 0) return state;
            const upArr = state.list.slice();
            upArr[upIndex] = upArr.splice(upIndex - 1, 1, upArr[upIndex])[0];
            saveOrdersSort(upArr);

            return {
                ...state,
                list: upArr
            };

        case actions.ORDERS_POSITION_DOWN:
            const downIndex = state.list.findIndex(o => o.orderNumber === action.payload);
            if (downIndex === state.list.length - 1) return state;
            const downArr = state.list.slice();
            downArr[downIndex] = downArr.splice(downIndex + 1, 1, downArr[downIndex])[0];
            saveOrdersSort(downArr);

            return {
                ...state,
                list: downArr
            };

        case actions.ORDERS_SELECT:
            return {
                ...state,
                selectedOrder: action.payload
            };

        default:
            return state;
    }
};