import React from 'react';
import ReactDOM from 'react-dom';
import App from "./App";
import {createStore, applyMiddleware} from "redux";
import { composeWithDevTools } from 'redux-devtools-extension';
import {Provider} from "react-redux";
import {rootReducer} from "./reducers/index";
import thunk from "redux-thunk";

import ApiService from "./services/api-service";
const apiService = new ApiService();

const composeEnhancers = composeWithDevTools({
    trace: true
});

const store = createStore(rootReducer, composeEnhancers(applyMiddleware(thunk.withExtraArgument(apiService))));

ReactDOM.render(
    <Provider store={store}>
        <App/>
    </Provider>, document.getElementById('root')
);
