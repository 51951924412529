import React, {Component} from 'react';
import {connect} from "react-redux";
import OrdersList from "./orders-list";
import PageHeader from "../page-header";
import classNames from 'classnames';
import {allOrders, deliveredOrders} from "../../selectors/selectors";
import * as actions from "../../actions/actions";
import Preloader from "../preloader";
import Types from "../../types";
import AppConfig from "../../AppConfig";
import {Button} from "antd";
import ReloadOutlined from "@ant-design/icons/lib/icons/ReloadOutlined";

const tabs = [
    {id: 0, label: 'Все'},
    {id: 1, label: 'Доставленные'},
];

class Delivery extends Component {
    constructor(props) {
        super(props);
        this.state = {
            activeTab: tabs[0].id,
        };
        this.interval = null;
    }

    componentDidMount() {
        this.onReload();
        this.interval = setInterval(() => {
            this.onReload();
        }, 5000);
    }

    componentWillUnmount() {
        clearInterval(this.interval);
    }

    onReload = () => {
        if (!this.props.orders.error && ((+new Date() - this.props.orders.lastOrdersUpdate) / 1000 >= AppConfig._updateOrdersInterval)) {
            this.props.dispatch(actions.getOrders());
        }
    };

    completeOrder = (orderId, orgId) => {
        this.props.dispatch(actions.completeOrder(orderId, orgId));
    };

    renderFilters = () => (
        <div className="Delivery__filters">
            {tabs.map(tab => (
                <div
                    key={tab.id}
                    onClick={() => this.setState({ activeTab: tab.id })}
                    className={classNames("Delivery__filters_item", {'active': tab.id === this.state.activeTab})}
                >
                    {tab.label}
                </div>
            ))}
        </div>
    );

    changePosition = (id, isSideUp) => {
        if (isSideUp) {
            this.props.dispatch(actions.ordersPositionUp(id));
        } else {
            this.props.dispatch(actions.ordersPositionDown(id));
        }
    };

    showOnMap = (id) => {
        this.props.dispatch(actions.selectOrder(id));
        this.props.history.push('/map');
    };

    renderContent = () => (
        <div className="Delivery__content">
            <div className="Delivery__refresh">
                <Button
                    type="primary"
                    onClick={() => this.props.dispatch(actions.getOrders())}
                >
                    <ReloadOutlined />
                </Button>
            </div>
            {this.renderFilters()}
            {this.state.activeTab === tabs[0].id &&
            <OrdersList
                onChangePosition={this.changePosition}
                orders={this.props.allOrders}
                onShowOnMap={this.showOnMap}
                onComplete={this.completeOrder}
            />}
            {this.state.activeTab === tabs[1].id &&
            <OrdersList
                orders={this.props.deliveredOrders}
            />}
        </div>
    );

    render() {
        return (
            <div className="Delivery">
                <PageHeader title="Доставки"/>
                {this.props.status === Types.FETCHING ?
                    <Preloader/>
                    :
                    this.renderContent()
                }
            </div>
        )
    }
}

const mapStateToProps = (state) => {
    const { orders } = state;
    return {
        allOrders: allOrders(state),
        deliveredOrders: deliveredOrders(state),
        courierId: state.app.id,
        status: state.orders.status,
        orders,
    }
};

const mapDispatchToProps = (dispatch) => {
    return {
        dispatch
    }
};

export default connect(mapStateToProps, mapDispatchToProps)(Delivery);