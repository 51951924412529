import React from 'react';
import Types from "../../types";
import DownOutlined from "@ant-design/icons/lib/icons/DownOutlined";
import UpOutlined from "@ant-design/icons/lib/icons/UpOutlined";
import classNames from "classnames";

const OrdersList = ({ orders, onChangePosition, onShowOnMap, onComplete }) => {

    const renderChildren = (order, idx) => (
        <div className="OrdersList__child" key={order.orderId}>
            <div className='OrdersList__child_id'>
                <span className="value">{"№ " + order.orderNumber + ' | ' + order.orderPhone}</span>
            </div>
            <div className='OrdersList__child_date'>
                <span className="value"><b>{order.orderExpectedDateTime1} - {order.orderExpectedDateTime2}</b></span>
            </div>
            <div className='OrdersList__child_address'>
                <span className="value">{order.addressString1}, {order.addressString2}</span>
            </div>
            <div className='OrdersList__child_tel'>
                <span className="label">Инфо: </span>
                <span className="value">{order.body}</span>
            </div>
            {order.status === Types.ordersStatus.pending.value &&
            <div className='OrdersList__child_controls'>
                <div className="delivered-button button" onClick={() => onComplete(order.orderId, order.organizationId)}>Доставлено</div>
                <a className="call-button button" href={`tel:${order.clearOrderPhone}`}>Позвонить</a>
                <div className="map-button button" onClick={() => onShowOnMap(order.orderNumber)}>На карте</div>
            </div>}
            {onChangePosition && order.status === Types.ordersStatus.pending.value &&
            <div className="OrdersList__child_sort">
                <div className={classNames("sort-button", {'disabled': idx === orders.length - 1})} onClick={() => onChangePosition(order.orderNumber, false)}>
                    <DownOutlined />
                </div>
                <div className={classNames("sort-button", {'disabled': idx === 0})} onClick={() => onChangePosition(order.orderNumber, true)}>
                    <UpOutlined />
                </div>
            </div>}
            {order.status !== Types.ordersStatus.pending.value &&
            <div className={classNames("OrdersList__child_delivered-status", {"late": !order.deliveryInExpectedDateTime})}>
                {`Доставлено в ${order.deliveredDateTime}`}
            </div>}
        </div>
    );

    const list = orders.map((o, idx) => renderChildren(o, idx));

    return (
        <div className="OrdersList">
            {list}
        </div>
    )
};

export default OrdersList