import React from 'react';
import {connect} from "react-redux";
import * as actions from "../actions/actions";
import PageHeader from "./page-header";
import {Switch} from "antd";
import Types from "../types";
import AppConfig from "../AppConfig";

const Menu = ({ theme, logout, switchTheme, enableLocation }) => {

    const onChangeTheme = (defaultValue) => {
        if (defaultValue) {
            switchTheme(AppConfig._defaultTheme)
        } else {
            const anotherTheme = Object.values(Types.themes).filter(t => t !== AppConfig._defaultTheme)[0];
            switchTheme(anotherTheme);
        }
    };

    return (
        <div className="Menu">
            <div className="Menu__content">
                <PageHeader title="Меню"/>
                <div className="Menu__items">
                    <div className="Menu__item" onClick={() => onChangeTheme(theme !== AppConfig._defaultTheme)}>
                        <span className="Menu__item_label">Тёмная тема:</span>
                        <Switch
                            color={"green"}
                            checked={theme === Types.themes.dark}
                            onChange={onChangeTheme} />
                    </div>

                    <div className="Menu__item">
                        <div className="Menu__item_label">Локация:</div>
                        <div className="Menu__item_value">{enableLocation ? "Включена" : "Отключена"}</div>
                    </div>

                    <div className="Menu__item" onClick={logout}>
                        Выйти
                    </div>
                </div>
            </div>
        </div>
    );
};

const mapStateToProps = (state) => {
    const {theme} = state.app;
    const { enableLocation } = state.location;
    return {
        theme,
        enableLocation
    }
};

const mapDispatchToProps = (dispatch) => {
    return {
        switchTheme: (theme) => dispatch(actions.setTheme(theme)),
        logout: () => dispatch(actions.logOut())
    }
};

export default connect(mapStateToProps, mapDispatchToProps)(Menu);