import React from 'react';

const PageHeader = ({ title }) => {
    if (!title) return null;

    return (
        <div className="PageHeader">
            <div className="PageHeader__title">
                {title}
            </div>
        </div>
    )
};

export default PageHeader;