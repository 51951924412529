import React from "react";
import StopOutlined from "@ant-design/icons/lib/icons/StopOutlined";
import PropTypes from "prop-types";

const PageNotFound = ({ message }) => (
    <div className="PageNotFount">
        <StopOutlined />
        <span>{message}</span>
    </div>
);

PageNotFound.defaultProps = {
    message: "Страница не найдена..."
};

PageNotFound.propTypes = {
    message: PropTypes.string,
};

export default PageNotFound;