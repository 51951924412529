import React, {Component} from "react";
import PageHeader from "./page-header";
import {connect} from "react-redux";
import {YMaps, Map, RouteEditor, ZoomControl} from "react-yandex-maps";
import {ordersOptions, pendingOrders} from "../selectors/selectors";
import {Select} from "antd";
import * as actions from "../actions/actions";
import PageNotFound from "./page-not-found";

const multiRouteOption = {
  value: 'multi',
  label: "Мультимаршрут"
};

class RouteMap extends Component {
    constructor(props) {
        super(props);
        this.map = null;
        this.ymaps = null;
        this.route = null;
        this.mapState = {
            center: [this.props.location.latitude, this.props.location.longitude],
            zoom: 12,
            controls: ['fullscreenControl', 'geolocationControl', 'trafficControl'],
        };
    }

    componentDidMount() {
        if (!this.props.selectedOrder && this.props.options.length) this.props.dispatch(actions.selectOrder(this.props.options[0].value));
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (this.props.selectedOrder && this.map && this.ymaps && prevProps.selectedOrder !== this.props.selectedOrder) {
            const {orders, location, selectedOrder} = this.props;
            const currentOrder = orders.find(o => o.orderNumber === selectedOrder);
            this.map.geoObjects.removeAll();

            if (selectedOrder === 'multi') {
                const points = this.props.orders.map(o => o.addressString1);
                const limitedPoints = points.slice(0, 4);
                this.ymaps.route([[location.latitude, location.longitude], ...limitedPoints], {
                    avoidTrafficJams: true,
                    mapStateAutoApply: true
                })
                    .then(route => {
                        route.getPaths().options.set({strokeColor: "0000ffff", opacity: 0.9});
                        this.map.geoObjects.add(route);
                    });
            } else {
                this.ymaps.route([[location.latitude, location.longitude], currentOrder.addressString1], {
                    avoidTrafficJams: true,
                    mapStateAutoApply: true
                })
                    .then(route => {
                        route.getPaths().options.set({strokeColor: "0000ffff", opacity: 0.9});
                        this.map.geoObjects.add(route);
                    });
            }
        }
    }

    componentWillUnmount() {
        this.props.dispatch(actions.selectOrder(''));
    }

    handleApiAvaliable = ymaps => {
        const {orders, location} = this.props;
        const selectedOrder = orders.find(o => o.orderNumber === this.props.selectedOrder);
        if (selectedOrder) {
            this.ymaps = ymaps;
            ymaps.route([[location.latitude, location.longitude], selectedOrder.addressString1], {
                avoidTrafficJams: true,
                mapStateAutoApply: true
            })
                .then(route => {
                    route.getPaths().options.set({strokeColor: "0000ffff", opacity: 0.9});
                    this.map.geoObjects.add(route);
                });
        }
    };

    render() {
        const {orders, options, location} = this.props;
        return (
            <div className="RouteMap">
                <PageHeader title="Карта маршрута"/>
                {location.enableLocation ?
                    <div className="RouteMap__content">
                        <div className="RouteMap__order-selector">
                            {orders.length > 0 &&
                            <Select
                                size="large"
                                className="Header-content__dropdown"
                                placeholder="Выберите место"
                                options={[...options, multiRouteOption]}
                                onChange={(value) => this.props.dispatch(actions.selectOrder(value))}
                                value={this.props.selectedOrder}
                            />}
                        </div>
                        <YMaps
                            style={{width: '100%', height: '100%'}}
                            onApiAvaliable={ymaps => this.handleApiAvaliable(ymaps)}
                            query={{apikey: 'e60e2992-cc07-48b6-bd8b-3250162140d0'}}
                        >
                            <Map
                                state={this.mapState}
                                modules={['control.FullscreenControl', 'control.GeolocationControl']}
                                className="RouteMap__map"
                                instanceRef={(ref) => this.map = ref}
                            >
                                <RouteEditor/>
                                <ZoomControl/>
                            </Map>
                        </YMaps>
                    </div>
                : <PageNotFound message="Геолокация отключена, работа с картой невозможна!"/>}
            </div>
        )
    }
}

const mapStateToProps = (state) => {
    const {location} = state;
    const { selectedOrder } = state.orders;
    return {
        location,
        orders: pendingOrders(state),
        options: ordersOptions(state),
        selectedOrder,
    }
};

const mapDispatchToProps = (dispatch) => {
    return {
        dispatch
    }
};

export default connect(mapStateToProps, mapDispatchToProps)(RouteMap);