import * as actions from "../actions/actions";
import StorageService from "../services/storage-service";

const LATITUDE = "LATITUDE";
const LONGITUDE = "LONGITUDE";

const latitude = StorageService.get(LATITUDE);
const longitude = StorageService.get(LONGITUDE);

const initialState = {
    enableLocation: false,
    latitude: latitude || 0,
    longitude: longitude || 0,
};

export const location = (state = initialState, action) => {

    switch (action.type) {
        case actions.LOCATION:
            StorageService.set(LATITUDE, action.payload.latitude);
            StorageService.set(LONGITUDE, action.payload.longitude);
            return {
                ...state,
                latitude: action.payload.latitude,
                longitude: action.payload.longitude,
            };

        case actions.ENABLE_LOCATION:
            return {
                ...state,
                enableLocation: action.payload,
            };

        default:
            return state;
    }
};