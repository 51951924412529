import Types from "./types";

export default class AppConfig {
    static _notificationDuration = 1.5; //seconds

    static _defaultTheme = Types.themes.dark;

    static _updateOrdersInterval = 90; //seconds

    static _updateLocationInterval = 300; //seconds
}