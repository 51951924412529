import React from "react";
import classNames from 'classnames';
import {connect} from "react-redux";
import {withRouter} from "react-router-dom";
import {AppstoreOutlined, EnvironmentOutlined, MenuOutlined, UserOutlined} from "@ant-design/icons/lib/icons";

const TabBar = ({ordersList, history, location}) => {
    const tabsSet = [
        {
            id: 1,
            isAvailable: true,
            label: "Доставки",
            count: ordersList.length,
            icon: <AppstoreOutlined/>,
            url: '/'
        },
        {
            id: 2,
            isAvailable: true,
            label: "Карта",
            icon: <EnvironmentOutlined/>,
            url: '/map'
        },
        {
            id: 3,
            isAvailable: true,
            label: "Личная",
            icon: <UserOutlined/>,
            url: '/personal'
        },
        {
            id: 4,
            isAvailable: true,
            label: "Меню",
            icon: <MenuOutlined/>,
            url: '/menu',
        },
    ];

    const _renderTab = (tab) => {
        if (!tab.isAvailable) return null;
        return (
            <div
                onClick={() => history.push(tab.url)}
                className={classNames("TabBar__tab", {'active': location.pathname === tab.url})}
                key={tab.id}
                style={{flex: `1 0 ${100 / tabsSet.length}%`}}
            >
                <div className="TabBar__tab_icon">{tab.icon}</div>
                <div className="TabBar__tab_label">{tab.label}</div>
                {tab.count ? <div className="TabBar__tab_count">{tab.count}</div> : ''}
            </div>
        )
    };

    const tabsList = tabsSet.map(tab => _renderTab(tab));
    return (
        <div className="TabBar">
            {tabsList}
        </div>
    )
};

const mapStateToProps = (state) => {
    const {list} = state.orders;
    return {
        ordersList: list
    }
};

const mapDispatchToProps = (dispatch) => {
    return {
        dispatch
    }
};

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(TabBar));