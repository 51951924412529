import { createSelector } from "reselect";
import Types from "../types";

const orders = state => state.orders.list;

export const extendedOrders = createSelector(
    [orders],
    (orders) => {
        return orders.map(o => {
            if (!o.isDelivered) {
                o.status = Types.ordersStatus.pending.value;
                return o;
            } else if (o.deliveryInExpectedDateTime) {
                o.status = Types.ordersStatus.delivered.value;
                return o;
            } else {
                o.status = Types.ordersStatus.deliveredLate.value;
                return o;
            }
        });
    }
);

export const allOrders = createSelector(
    [extendedOrders],
    (orders) => {
        const pendingOrders = [];
        const otherOrders = [];
        orders.forEach(o => {
           if (o.status === Types.ordersStatus.pending.value) {
               pendingOrders.push(o);
           } else otherOrders.push(o)
        });
        return [...pendingOrders, ...otherOrders];
    }
);

export const deliveredOrders = createSelector(
    [extendedOrders],
    (orders) => {
        return orders.filter(o => o.status === Types.ordersStatus.delivered.value || o.status === Types.ordersStatus.deliveredLate.value);
    }
);

export const pendingOrders = createSelector(
    [extendedOrders],
    (orders) => {
        return orders.filter(o => o.status === Types.ordersStatus.pending.value);
    }
);

export const ordersOptions = createSelector(
    [pendingOrders],
    (orders) => {
        return orders.length ? orders.map(o => {
            return {
                value: o.orderNumber,
                label: o.addressString1,
            }
        }) : [];
    }
);
