import React from "react";
import {Redirect, Route} from "react-router-dom";

const AuthRoute = ({component: Component, isLogged, ...rest}) => {

    return (
        <Route
            render={(props) => isLogged ? <Component {...props}/> : <Redirect to='/login'/>}
            {...rest}
        />
    );
};

export default AuthRoute;
