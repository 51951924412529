import {message} from "antd";
import AppConfig from "../AppConfig";

export const THEME = "THEME";

export const LOG_IN = "LOG_IN";
export const LOGIN_STATUS_FETCHING = "LOGIN_STATUS_FETCHING";
export const LOGIN_ERROR = "LOGIN_ERROR";
export const LOG_OUT = "LOG_OUT";

export const ORDERS = "ORDERS";
export const ORDERS_STATUS_FETCHING = "ORDERS_STATUS_FETCHING";
export const ORDERS_ERROR = "ORDERS_ERROR";
export const ORDERS_SELECT = "ORDERS_SELECT";

export const LOCATION = "LOCATION";
export const ENABLE_LOCATION = "ENABLE_LOCATION";

export const ORDERS_POSITION_UP = "ORDERS_POSITION_UP";
export const ORDERS_POSITION_DOWN = "ORDERS_POSITION_DOWN";

export const setTheme = (payload) => ({type: THEME, payload});

export const logIn = (payload) => ({type: LOG_IN, payload});
export const logInStatusFetching = () => ({type: LOGIN_STATUS_FETCHING});
export const loginError = (payload) => ({type: LOGIN_ERROR, payload});
export const logOut = () => ({type: LOG_OUT});

export const orders = (payload) => ({type: ORDERS, payload});
export const ordersStatusFetching = () => ({type: ORDERS_STATUS_FETCHING});
export const ordersError = (payload) => ({type: ORDERS_ERROR, payload});

export const selectOrder = (payload) => ({type: ORDERS_SELECT, payload});

export const location = (payload) => ({type: LOCATION, payload});
export const enableLocation = (payload) => ({type: ENABLE_LOCATION, payload});

export const ordersPositionUp = (payload) => ({type: ORDERS_POSITION_UP, payload});
export const ordersPositionDown = (payload) => ({type: ORDERS_POSITION_DOWN, payload});

const errorHandler = (err, errorAction, dispatch) => {
    if (err.response) {
        if (err.response.status === 401) {
            dispatch(logOut());
            return;
        }
        dispatch(errorAction(err.response.data.message));
        message.error(err.response.data.message, AppConfig._notificationDuration, dispatch(errorAction('')))
    } else if (err.request) {
        dispatch(errorAction("Ошибка соединения, пожалуйста повторите попытку позже..."));
        message.error('Ошибка соединения, пожалуйста повторите попытку позже...', AppConfig._notificationDuration, dispatch(errorAction('')))
    } else {
        dispatch(errorAction("Что-то пошло не так..."));
        message.error('Что-то пошло не так...', AppConfig._notificationDuration, dispatch(errorAction('')))
    }
};

export const auth = (user) => (dispatch, getState, apiService) => {
    dispatch(logInStatusFetching());
    apiService.auth(user)
        .then(res => {
            dispatch(logIn(res.data));
        })
        .catch(err => errorHandler(err, loginError, dispatch))
};

export const getOrders = () => (dispatch, getState, apiService) => {
    const { id } = getState().app;
    dispatch(ordersStatusFetching());
    apiService.loadOrders(id)
        .then(res => {
            dispatch(orders(res.data));
        })
        .catch(err => errorHandler(err, ordersError, dispatch));
};

export const completeOrder = (orderId, orgId) => (dispatch, getState, apiService) => {
    const { id } = getState().app;
    apiService.completeOrders(orgId, id, orderId)
        .then(() => {
            dispatch(getOrders());
        })
        .catch(err => errorHandler(err, ordersError, dispatch));
};