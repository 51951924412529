import React from "react";
import PageHeader from "./page-header";
import {UserOutlined} from "@ant-design/icons/lib/icons";
import {connect} from "react-redux";

const Personal = ({ phone }) => {

    return (
        <div className="Personal">
            <PageHeader title="Личная страница"/>
            <div className="Personal__content">
                <div className="Personal__icon">
                    <UserOutlined/>
                </div>
                <div className="Personal__data">
                    <div className="Personal__data_block">
                        <div className="Personal__data_label">{'Телефон: '}</div>
                        <div className="Personal__data_value">{phone}</div>
                    </div>
                </div>
            </div>
        </div>
    )
};

const mapStateToProps = (state) => {
    const {phone} = state.app;
    return {
        phone
    }
};

export default connect(mapStateToProps, null)(Personal);