import React, {useEffect} from 'react';
import {Form, Input, Button} from 'antd';
import {connect} from "react-redux";
import logoLight from './assets/img/logoLight.png';
import logoDark from './assets/img/logoDark.png';
import * as actions from "../actions/actions";
import Types from "../types";

const Login = ({ isLogged, loginStatus, loginError, history, dispatch, theme }) => {
    useEffect(() => {
        if (isLogged) history.push("/");
    });

    const onFinish = values => {
        if (loginError) dispatch(actions.loginError(''));
        dispatch(actions.auth(values.login))
    };

    return (
        <div className="Login">
            <div className="Login__logo">
                <img src={theme === Types.themes.dark ? logoDark : logoLight} alt="logo"/>
            </div>
            <div className="Login__form">
                <Form
                    name="auth"
                    onFinish={onFinish}
                >
                    <Form.Item
                        label="Логин"
                        name="login"
                        rules={[{ required: true, message: 'Введите Ваш логин!' }]}
                    >
                        <Input />
                    </Form.Item>

                    <Form.Item className="submit">
                        <Button
                            loading={loginStatus === Types.FETCHING}
                            type="primary"
                            htmlType="submit"
                        >
                            Войти
                        </Button>
                    </Form.Item>
                </Form>
            </div>
        </div>
    );
};

const mapStateToProps = (state) => {
    const { isLogged, loginStatus, loginError, theme } = state.app;
  return {
      isLogged,
      loginStatus,
      loginError,
      theme
  }
};

const mapDispatchToProps = (dispatch) => {
    return {
        dispatch
    }
};

export default connect(mapStateToProps, mapDispatchToProps)(Login);